import { useState, useEffect } from 'react';
import axios from 'axios';
import useSelector from '../redux/typedHooks';

type DataObject = Record<string, string>;

interface props {
  token: string | undefined;
  url: string;
}

interface ReturnType {
  data: DataObject[] | undefined;
  isLoading: boolean;
  dataError: boolean;
}

export const useFormFieldData = ({ token, url }: props): ReturnType => {
  const [data, setData] = useState<DataObject[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataError, setDataError] = useState<boolean>(false);
  const { apimContentHub } = useSelector((state) => state.application);

  useEffect(() => {
    if (url) {
      try {
        setIsLoading(true);
        const headers: {
          'Ocp-Apim-Subscription-Key': string;
          Authorization?: string;
        } = {
          'Ocp-Apim-Subscription-Key': apimContentHub,
        };
        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }
        axios
          .get<DataObject[]>(url, {
            headers,
          })
          .then((res) => {
            setData(res.data);
            setIsLoading(false);
            setDataError(false);
          });
      } catch (e) {
        setIsLoading(false);
        setDataError(true);
      }
    }
  }, [url, token, apimContentHub]);

  return { data, isLoading, dataError };
};
