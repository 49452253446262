import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@naf/theme';
import { Select } from '@naf/input';
import { Loader } from '@naf/loader';
import { Text, TextVariant } from '@naf/text';
import { UseFormReturn } from 'react-hook-form';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { useFormFieldData } from '../../hooks/useFormFieldData';
import { useSessionStorage } from '../../hooks/useSessionStorage';

type DataObject = Record<string, string>;

interface Props {
  dataSource: string;
  valueKey: keyof DataObject;
  displayKey: keyof DataObject;
  onChange: (item: any) => void;
  skipBearerToken?: boolean;
  error?: boolean;
  placeholder?: string;
  width?: string;
  label?: string;
  value?: any;
  setValue?: UseFormReturn['setValue'];
  id?: string;
}

export const Typeahead = ({
  dataSource,
  valueKey,
  displayKey,
  onChange,
  skipBearerToken,
  error = false,
  placeholder,
  width,
  label,
  value,
  setValue,
  id,
}: Props) => {
  const { simpleToken } = useAuth0Token();
  const { data, isLoading, dataError } = useFormFieldData({
    token: skipBearerToken ? undefined : simpleToken,
    url: dataSource,
  });

  const [centerId, setCenterId] = useSessionStorage('centerId');
  const centerFromStorage = data?.find((center) => center.centerId === centerId);

  useEffect(() => {
    // Set center if selected on NAF center service landing page
    // TODO this should be done on the center form, not hardcoded here
    if (id && centerFromStorage && !value && setValue) {
      setValue(id, { value: centerFromStorage.title });
    }
  }, [centerFromStorage, setValue, id, value]);

  const options = data
    ? data.map((item: DataObject) => ({ label: item[displayKey], value: item[valueKey] }))
    : undefined;

  const getInitialValue =
    centerFromStorage && options ? options.find((option) => option.label === centerFromStorage.title) : undefined;

  const selectCenterId = useCallback(
    (title: string) => {
      if (data) {
        const centerObject = data.find((center) => center.title === title);
        setCenterId(centerObject?.centerId);
      }
    },
    [data, setCenterId],
  );

  if (isLoading) {
    return <Loader size="small" variant="neutral" />;
  }

  if (!isLoading && dataError) {
    return (
      <Text variant={TextVariant.ArticleTextHeader}>
        Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!
      </Text>
    );
  }

  if (options) {
    return (
      <ResponsiveSelect width={width}>
        <Select
          label={label}
          options={options}
          selected={getInitialValue}
          handleSelect={(option: { label: string; value: string }) => {
            onChange(option.value);
            if (id === 'nafCenter') {
              selectCenterId(option.value);
            }
          }}
          error={error}
          placeholder={placeholder}
          maxLines={6}
          width={width && width === 'Wide' ? '100%' : '256px'}
          value={{ label: value, value }}
        />
      </ResponsiveSelect>
    );
  }
  return null;
};

export const ResponsiveSelect = styled.div<{ width?: string }>`
  div {
    width: ${(props) => (props.width && props.width === 'Wide' ? '100%' : 'fit-content')};
  }
  @media (max-width: ${breakpoints.s}) {
    div {
      width: 100%;
    }
  }
`;
